import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from 'reactstrap'

import DataTable from 'react-data-table-component'
import ColumnsSettingsDropdownItem from "./Actions/ColumnsSettingsDropdownItem"
import {APIClient} from "../../helpers/api_helper"
import {useDispatch, useSelector} from "react-redux"
import classnames from "classnames"
import FiltersModal from "./FiltersModal"
import {ModalContext} from "../../contexts"
import FiltersComponent from "./FiltersComponent"

const BaseTable = (props) => {
  const {
    columns,
    selectedRowsHandler,
    url,
    additionalRequest,
    methodsRef,
    filterFields,
    title,
    actions,
    columnsSetting,
    columnsSettingHandler,
    conditionalRowStyles,
    storageName,
    tableRowsPerPage,
    filterStorageKey,
    clearingSelectedRowsList
  } = props
  const api = new APIClient
  const {openModal} = useContext(ModalContext)
  const {layoutModeType} = useSelector(state => ({
    layoutModeType: state.Layout.layoutModeType,
  }))
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [overlay, setOverlay] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(1)
  const [sortName, setSortName] = useState('')
  const [sortOrder, setSortOrder] = useState('desc')
  const [perPage, setPerPage] = useState(
    localStorage.getItem(tableRowsPerPage) ? Number(localStorage.getItem(tableRowsPerPage)) : 25
  )
  const filters = useRef([])
  const [selectedRows, setSelectedRows] = useState([])
  const [isColumnsSettingsDropdown, setIsColumnsSettingsDropdown] = useState(false)
  const loadingFinished = useRef(false)
  const [toggledClearRows, setToggleClearRows] = useState(false)

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        width: '50px',
      },
    },
  }

  const toggleColumnsSettingDropdown = (e) => {
    const contains = e.target.classList.contains('column_setting_input')
    const closest = e.target.closest('button')?.classList?.contains('column_setting_input')

    if (contains || closest) return

    setIsColumnsSettingsDropdown(!isColumnsSettingsDropdown)
  }

  const getData = (withOverlay) => {
    if (withOverlay) setOverlay(true)
    loadingFinished.current = false
    filters.current = []
    let storageFilter = JSON.parse(localStorage.getItem('table-filters')) // filterStorageKey

    if (storageFilter) {
      if (storageFilter.hasOwnProperty(filterStorageKey)) {
        storageFilter = Object.values(storageFilter[filterStorageKey])
      }

      if (storageFilter && storageFilter.length > 0 && !additionalRequest) {
        filters.current = storageFilter
      }
    }

    let data = {
      page: page,
      per_page: perPage,
      sort_order: sortOrder,
      sort_name: sortName,
    }

    // if (storageFilter && storageFilter.length > 0 && !additionalRequest) {
    //   filters.current = storageFilter
    // } else {
    //   filters.current = []
    // }

    filters.current.forEach(i => {
      let key = i.name + '[name]'
      let prefix = i.name

      if (data.hasOwnProperty(key)) {
        prefix = prefix + '_1'
      } else {
      }

      data[prefix + '[name]'] = i.name

      if (i.operator) {
        data[prefix + '[operator]'] = i.operator
      }

      if (typeof i.value === 'object') {
        if (Array.isArray(i.value)) {
          data[prefix + '[value]'] = i.value.flatMap(item => item.value ?? item)
        } else {
          data[prefix + '[value]'] = i.value.value
        }
      } else {
        data[prefix + '[value]'] = i.value
      }
    })

    if (additionalRequest) {
      data = {...data, ...additionalRequest}
    }

    api.get(url, data).then(r => {
      setTableData(r.data)
      setTotalRows(r.total)
    }).finally(() => {
      setLoading(false)
      setOverlay(false)
      loadingFinished.current = true
    })
  }

  const onColumnsOrder = (nextOrder) => {
    if (storageName) {
      localStorage.setItem(storageName, JSON.stringify(nextOrder.map(i => i.id)))
    }
  }

  const dropdownMenuHeight = () => {
    const rdtTable = document.querySelector('.rdt_Table')
    const rdtPagination = document.querySelector('.rdt_Pagination')
    const dropdownMenuElement = document.querySelector('#columns_settings')

    if (rdtTable && dropdownMenuElement) {
      const tableHeight = rdtTable.clientHeight + (rdtPagination ? rdtPagination.clientHeight : 0)
      const dropdownMenuHeight = dropdownMenuElement.clientHeight

      if (dropdownMenuHeight > tableHeight) {
        dropdownMenuElement.style.maxHeight = `${tableHeight}px`
        dropdownMenuElement.style.overflowY = 'scroll'
      } else {
        dropdownMenuElement.style.maxHeight = 'none'
        dropdownMenuElement.style.overflowY = 'visible'
      }
    }
  }

  const toggleColumnForTable = (e) => {
    dispatch(columnsSettingHandler({id: e.target.name, params: {isVisible: e.target.checked}}))
  }

  const sortHandler = (sort, sortDirection) => {
    setSortName(sort.id)
    setSortOrder(sortDirection)
  }

  // Table handlers
  const pageChangeHandler = page => {
    setPage(page)
  }

  const perRowsChangeHandler = async (newPerPage, page) => {
    if (tableRowsPerPage) {
      localStorage.setItem(tableRowsPerPage, JSON.stringify(newPerPage))
    }
    setPerPage(newPerPage)
    setPage(page)
  }

  // Filters
  const filtersHandler = (filtersData) => {
    let oldData = JSON.parse(localStorage.getItem('table-filters')) ?? {}
    const data = {}
    data[filterStorageKey] = filtersData

    localStorage.setItem('table-filters', JSON.stringify({...oldData, ...data}))
    getData(true)
  }

  const openFiltersModalHandler = () => {
    openModal({
      title: 'Add filters',
      content: <FiltersModal filterFields={filterFields} filtersHandler={filtersHandler}/>,
      size: 'xl',
    })
  }

  const selectHandler = ({selectedRows}) => {
    setSelectedRows(selectedRows)
    selectedRowsHandler(selectedRows)
  }

  const clearSelectRows = () => {
    setToggleClearRows(!toggledClearRows)
  }

  useImperativeHandle(methodsRef, () => ({
    updateTable: (withClearing = true) => {
      getData(true)
      if (withClearing) {
        clearSelectRows()
        setSelectedRows([])
        clearingSelectedRowsList && clearingSelectedRowsList()
      }
    }
  }))

  const refreshButton = (
    <Button
      key="refreshButton"
      color="text-muted"
      outline
      className="hstack justify-content-center text-muted align-items-center fs-20 rotate"
      onClick={() => {
        if (loadingFinished.current) {
          getData(true)
        }
      }}
    >
      <i className="ri-refresh-line"></i>
    </Button>
  )

  const contextActionsArray = [refreshButton, actions]

  const actionsMemo = React.useMemo(() => (
    <>
      {/* Refresh button */}
      {refreshButton}

      {/* Columns settings dropdown */}
      {(columnsSettingHandler !== undefined && columnsSetting !== undefined) &&
        <Dropdown
          isOpen={isColumnsSettingsDropdown}
          toggle={toggleColumnsSettingDropdown}
        >
          <DropdownToggle tag="button" className="btn text-muted fs-20" id="dropdownMenuButton">
            <i className="ri-list-settings-fill"></i>
          </DropdownToggle>
          <DropdownMenu end id="columns_settings">

            {columns?.length && columns.map((column, idx) => {
              if (typeof column === 'object' && !column.omit) {
                return <ColumnsSettingsDropdownItem
                  key={idx}
                  column={column}
                  columnsSetting={columnsSetting}
                  toggleColumnForTable={toggleColumnForTable}
                />
              }

              return null
            })}

          </DropdownMenu>
        </Dropdown>
    }
    </>
  ), [loadingFinished, isColumnsSettingsDropdown, additionalRequest])

  // Get data
  useEffect(() => {
    getData(true)
  }, [page, perPage, sortOrder, sortName, columns])

  useEffect(() => {
    dropdownMenuHeight()
  }, [isColumnsSettingsDropdown])

  return (
    <React.Fragment>
      {filterFields?.length ?
        <FiltersComponent
          updateTable={getData}
          filtersList={filters.current}
          filterStorageKey={filterStorageKey}
          openFiltersModalHandler={openFiltersModalHandler}
          filtersHandler={filtersHandler}
          filterFields={filterFields}
          selectedRows={selectedRows}
        /> : null
      }

      <Row className="mb-3">
        <Col className="col-12 dropdown_area">
          <DataTable
            className={classnames({'rdt_Table-overlay': overlay})}
            theme={layoutModeType === 'dark' ? 'dark' : 'default'}
            dense={true}
            title={title}
            columns={columnsSetting ?
              columns.filter(i => columnsSetting[i.id]?.isVisible !== false)
                .sort((a, b) => {
                  const storageOrder = localStorage.getItem(storageName)
                  if (storageOrder && storageOrder.length > 0) {
                    const orderArray = JSON.parse(storageOrder)
                    return orderArray.indexOf(a.id) - orderArray.indexOf(b.id)
                  }
                  return false
                }) :
              columns
            }
            data={tableData}
            selectableRows
            selectableRowsHighlight
            onSelectedRowsChange={selectHandler}
            contextActions={contextActionsArray || []}
            progressPending={loading}
            sortServer
            onSort={sortHandler}
            pagination
            paginationServer
            paginationRowsPerPageOptions={[25, 50, 100, 250]}
            paginationPerPage={perPage}
            onChangeRowsPerPage={perRowsChangeHandler}
            paginationTotalRows={totalRows}
            onChangePage={pageChangeHandler}
            actions={actionsMemo}
            conditionalRowStyles={conditionalRowStyles && conditionalRowStyles}
            customStyles={customStyles}
            clearSelectedRows={toggledClearRows}
            onColumnOrderChange={onColumnsOrder}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BaseTable
