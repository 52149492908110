import React, {useContext, useState} from 'react'
import {ModalContext} from "../../../../../contexts"
import {Button, Input, Label} from "reactstrap"
import {changeExchangeFee} from "../../../../../api/fees";
import {toast} from "react-toastify";

const ExchangeFeesConfigModal = ({customer_id}) => {
  const {closeModal} = useContext(ModalContext)
  const [feeValue, setFeeValue] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    e.target.disabled = true

    changeExchangeFee(feeValue, customer_id).then(r => {
      if (r.success) {
        toast.success(r.message)
        closeModal()
      } else {
        toast.error(r.message)
      }
    }).finally(() => {
      e.target.disabled = false
    })
  }

  return (
    <>
      <div>
        <div className='form-group'>
          <Label className="mb-0 form-label">Fee value</Label>
          <Input
            className="form-control"
            type="text"
            value={feeValue}
            onChange={(e) => setFeeValue(e.target.value)}
            autoComplete="off"
            placeholder='4.0%'
          />
        </div>

        <div className="d-flex justify-content-end mt-3">
          <Button type="button" className="btn btn-success" onClick={(e) => submitHandler(e)}>
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default ExchangeFeesConfigModal
