import React, {useContext, useImperativeHandle, useMemo, useRef} from 'react'
import {Link} from "react-router-dom"
import BaseTable from "../../../Components/Tables/BaseTable"
import {prettyDate} from "../../../helpers/utils"
import {capitalize} from "lodash"
import {deleteSecurity} from "../../../api/securities"
import {toast} from "react-toastify"
import {ModalContext} from "../../../contexts"
import EditSecurity from "./Modals/EditSecurity"
import {useUserCan} from "../../../Components/Hooks/UserHooks"
import SecurityDetails from "../../Customers/Profile/Modals/SecurityDetails"

const SecuritiesTable = ({methodsRef, customerId = ''}) => {
  const baseTableMethodsRef = useRef(null)
  const {openModal} = useContext(ModalContext)
  const tableRowsPerPage = 'securities_rows_per_page'

  // Permissions
  const canManageSecurities = useUserCan('manage_securities')

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  useImperativeHandle(methodsRef, () => ({
    updateTable: () => updateTable()
  }))

  const openEditModalHandler = (security) => {
    openModal({
      title: `Edit "${security.name}"`,
      content: <EditSecurity data={security} updateTable={updateTable}/>,
      size: 'lg'
    })
  }

  const openSecurityDetailsModal = (item) => {
    openModal({
      title: `"${item.name}" details`,
      content: <SecurityDetails item={item} updateTable={updateTable}/>,
    })
  }

  const deleteSecuritiesHandler = (id, name) => {
    if (confirm(`Do you really want to delete "${name}"?`)) {
      deleteSecurity(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>ID</span>,
        selector: row => (customerId ?
          <a href="#" onClick={() => openSecurityDetailsModal(row)}>#{row.id}</a>
          : <>#{row.id}</>
        ),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "name",
        name: <span className='font-weight-bold fs-14'>Name</span>,
        selector: row => row.name,
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "type",
        name: <span className='font-weight-bold fs-14'>Type</span>,
        selector: row => capitalize(row.type),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "created_at",
        name: <span className='font-weight-bold fs-14'>Created At</span>,
        selector: row => prettyDate(row.created_at),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "actions",
        name: <span className='font-weight-bold fs-14'>Actions</span>,
        width: "110px",
        selector: row => (
          <>
            {canManageSecurities &&
            <div className="hstack gap-3 flex-wrap">
                {!customerId ?
                  <Link to="#" className="link-success fs-15" onClick={() => {openEditModalHandler(row)}}>
                    <i className="ri-edit-line"></i>
                  </Link> : null
                }
                <Link to="#" className="link-danger fs-15" onClick={() => {deleteSecuritiesHandler(row.id, row.name)}}>
                  <i className="ri-delete-bin-line"></i>
                </Link>
            </div>}
          </>
        ),
        omit: false
      }
    ], [canManageSecurities])

  return (
    <BaseTable
      columns={columns}
      title="Securities"
      filterFields={[]}
      url="/spa/securities/data"
      selectedRowsHandler={() => {}}
      methodsRef={baseTableMethodsRef}
      tableRowsPerPage={tableRowsPerPage}
      // additionalRequest={customerId ? {customer_id: customerId} : null}
    />
  )
}

export default SecuritiesTable
