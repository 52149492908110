import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "../../../../contexts"
import {Button, Table} from "reactstrap"
import {capitalize} from "lodash"
import {prettyDate} from "../../../../helpers/utils"
import Select from "react-select"
import {selectListData} from "../../../../api/general"

const SecurityDetails = ({item}) => {
  const {closeModal} = useContext(ModalContext)
  const [itemData, setItemData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [statusOptions, setStatusOptions] = useState([])

  useEffect(() => {
    if (!item) return

    const metaData = Object.entries(item?.meta.meta).map(([key, values]) => ({...values}))

    setItemData([
      {label: 'Name', value: item.meta.name},
      {label: 'Created at', value: prettyDate(item.created_at)},
      {label: 'Type', value: capitalize(item.meta.type)},
      ...metaData.filter(i => !i.hidden_in_details)
    ])
  }, [item])

  useEffect(() => {
    selectListData('securities_statuses_list').then(r => {
      setStatusOptions(r)
    })
  }, [])

  return (
    <div className="d-flex flex-column">
      <Table responsive className="table table-striped">
        <tbody>
        {(itemData && itemData.length) ? itemData.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <tr>
                <td className="text-nowrap fw-bold">
                    <span key={idx}>
                      {item.label}
                    </span>
                </td>
                <td className="text-nowrap text-truncate fw-semibold">
                  {(item.type === 'select' && item.value) ? capitalize(item.value) : item.value ? item.value : '-'}
                </td>
              </tr>
            </React.Fragment>
          )
        }) : null}
        <tr className="text-nowrap">
        </tr>

        </tbody>
      </Table>

      <div className="form-group mb-4 d-flex align-items-center">
        <span className="mx-3 fw-bold">Status</span>
        <Select
          className="w-100"
          placeholder="Choose..."
          name="status"
          value={selectedStatus}
          onChange={selected => setSelectedStatus(selected)}
          options={statusOptions}
        />
      </div>

      <div className="d-flex w-100 justify-content-between">
        <Button type="button" className="btn btn-warning" onClick={closeModal}>Close</Button>
        <Button type="button" className="btn btn-success" onClick={() => {
        }}>Save</Button>
      </div>
    </div>
  )
}

export default SecurityDetails
