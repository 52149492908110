import React, {useContext, useEffect, useState} from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import classnames from "classnames"
import {Link} from "react-router-dom"
import {ModalContext} from "../../contexts"
import SavePresetModal from "./Filters/SavePresetModal"
import MassActionsForm from "./Filters/MassActions/MassActionsForm"
import {useUserCan} from "../Hooks/UserHooks"
import BasicFilters from "./Filters/BasicFilters"
import {clearTableFilters, refreshFromStorage} from "../../slices/table-filters/reducer"
import {useDispatch} from "react-redux"

const FiltersComponent = (props) => {
  const {
    updateTable,
    filtersList,
    filterStorageKey,
    openFiltersModalHandler,
    filtersHandler,
    filterFields,
    selectedRows
  } = props
  const {openModal} = useContext(ModalContext)
  const dispatch = useDispatch()
  const [isMouseOnCloseFilter, setIsMouseOnCloseFilter] = useState(false)
  const [activeFilters, setActiveFilters] = useState([])
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [presetsList, setPresetsList] = useState([])
  const [showMassActionsForm, setShowMassActionsForm] = useState(false)

  // Permissions
  const canViewMassActions = useUserCan('have_access_to_customers')

  const updateFilters = (uuid) => {
    if (activeFilters && activeFilters.length === 1) {
      filtersHandler([])
    } else {
      setActiveFilters(activeFilters.filter(i => i.uuid !== uuid))
    }
  }

  const getPresetsList = () => {
    if (!filterStorageKey) return console.log('Code 64e9f7a03f87f')

    let storagePresets = JSON.parse(localStorage.getItem(`${filterStorageKey}-presets`))

    if (storagePresets && storagePresets.length > 0) {
      setPresetsList(storagePresets.flatMap((preset) => Object.keys(preset)))
    } else {
      setPresetsList([])
    }
  }

  const filterByPreset = (preset) => {
    if (!filterStorageKey) return console.log('Code 64e9f7a03f87e')

    let storagePresets = JSON.parse(localStorage.getItem(`${filterStorageKey}-presets`))
    let selectedPreset = storagePresets.find(i => Object.keys(i)[0] === preset)
    filtersHandler(...Object.values(selectedPreset))

    for (let i in selectedPreset) {
      dispatch(refreshFromStorage({filterStorageKey, data: selectedPreset[i]}))
    }
  }

  const deletePresetHandler = (e, preset) => {
    e.stopPropagation()
    if (!filterStorageKey) return console.log('Code 64e9f7a03f87d')

    let storagePresets = JSON.parse(localStorage.getItem(`${filterStorageKey}-presets`))
    localStorage.setItem(
      `${filterStorageKey}-presets`, JSON.stringify(storagePresets.filter(i => Object.keys(i)[0] !== preset))
    )

    getPresetsList()
  }

  const openSavePresetModal = () => {
    openModal({
      title: 'Save preset',
      content: <SavePresetModal
        filterStorageKey={filterStorageKey}
        updatePresetsList={getPresetsList}
      />
    })
  }

  const clearActiveFilters = () => {
    if (!filterStorageKey) return console.log('Code 64e9f7a03f87c')
    let oldData = JSON.parse(localStorage.getItem('table-filters'))

    if (oldData && filterStorageKey) {
      if (oldData.hasOwnProperty(filterStorageKey)) {
        delete oldData[filterStorageKey]

        localStorage.setItem('table-filters', JSON.stringify(oldData))
        dispatch(clearTableFilters(filterStorageKey))
      }
    }

    updateTable(true)
  }

  useEffect(() => {
    setActiveFilters(filtersList)

    if (!filtersList.length) {
      setShowMassActionsForm(false)
    }
  }, [filtersList])

  useEffect(() => {
    getPresetsList()
  }, [filterStorageKey])

  // useEffect(() => {
  //   if (!localStorage.getItem('filtersType')) {
  //     localStorage.setItem('filtersType', 'advancedFilters')
  //   }
  // }, [])
  //
  // useEffect(() => {
  //   setLocalFiltersType(localStorage.getItem('filtersType'))
  // }, [])

  return (
    <>
      <Card className="p-3">
        {/*{localFiltersType === 'advancedFilters' ?*/}
        <>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <div>

                {/* BEGIN Presets button */}
                <ButtonGroup>
                  <UncontrolledDropdown>
                    <DropdownToggle tag="button" className="btn btn-success border-0">
                      Presets <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl-end dropdown-menu-end">
                      {(presetsList?.length > 0) && presetsList.map((preset, idx) => {
                        return (
                          <DropdownItem
                            key={idx}
                            className="d-flex justify-content-between align-items-center"
                            onClick={() => filterByPreset(preset)}
                          >
                            {preset}
                            <Link
                              to="#"
                              onClick={(e) => {
                                if (confirm(`Do you really want to delete "${preset}"`)) {
                                  deletePresetHandler(e, preset)
                                }
                              }}
                              className="cursor-pointer"
                            >
                              <i className="ri-delete-bin-2-line text-danger fs-14 ms-1"></i>
                            </Link>
                          </DropdownItem>
                        )
                      })}
                      {(presetsList?.length > 0) && <hr className="m-1"/>}
                      <DropdownItem className="d-flex" onClick={openSavePresetModal}>
                        {/*<i className="ri-save-2-line align-middle me-1"></i>*/}
                        <i className="ri-menu-add-fill me-1"></i>
                        <span>Save current filters</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonGroup>
                {/* END Presets button */}
              </div>

              {/* BEGIN Clear active filters button */}
              <div className="d-flex mt-3 mt-sm-0">
                <Button
                  type="btn"
                  className="btn btn-label btn-outline-danger waves-effect right waves-light"
                  onClick={clearActiveFilters}
                  color="white"
                >
                  <i className="ri-delete-bin-2-line label-icon align-middle fs-16 ms-2"></i> Clear active filters
                </Button>
              </div>
              {/* END Clear active filters button */}
            </div>

            <div className="d-flex align-items-center py-1 mt-2">
              <hr className="p-0 m-0 flex-1 me-2"/>
              <span className="text-muted">Filters</span>
            </div>
          </div>
        </>

        {/* BEGIN BasicFilters */}
        <BasicFilters
          filterFields={filterFields}
          filtersHandler={filtersHandler}
          filterStorageKey={filterStorageKey}
        />
        {/* END BasicFilters */}

        {(window.location.pathname === '/customers' && canViewMassActions && activeFilters?.length) ?
          <>
            {/* BEGIN Mass action accordion */}
            <div className="d-flex align-items-center py-1 mt-2">
                  <span
                    className="text-muted me-2 cursor-pointer"
                    onClick={() => setShowMassActionsForm(s => !s)}
                  >
                    Apply mass actions to filter
                    <i className={classnames(
                      'mdi',
                      {'mdi-chevron-up': showMassActionsForm},
                      {'mdi-chevron-down': !showMassActionsForm}
                    )}>
                    </i>
                  </span>
              <hr className="p-0 m-0 flex-1 me-2"/>
            </div>
            {/* END Mass action accordion */}
          </> : null
        }

        {showMassActionsForm &&
          <MassActionsForm filters={activeFilters} selectedRows={selectedRows}/>
        }

      </Card>
    </>
  )
}

export default FiltersComponent
