import React, {useEffect, useState} from 'react'
import {Card, CardBody, CardHeader, Table} from "reactstrap"
import {getLastTransactions} from "../api/widget"
import {openLinkInNewTab, isTwoPrecision, prettyDate, toTwoPrecision} from "../helpers/utils"
import TransactionBadgeIcon from "../Components/Common/TransactionBadgeIcon"
import {Link} from "react-router-dom"
import {useUserCan} from "../Components/Hooks/UserHooks"

// Import Crypto-SVG
import btc from '../assets/images/svg/crypto-icons/btc.svg'
import eth from "../assets/images/svg/crypto-icons/eth.svg"
import ltc from "../assets/images/svg/crypto-icons/ltc.svg"
import dash from "../assets/images/svg/crypto-icons/dash.svg"
import usdt from "../assets/images/svg/crypto-icons/usdt.svg"
import usd from "../assets/images/svg/crypto-icons/usd.svg"
import eur from "../assets/images/svg/crypto-icons/eur.svg"
import gbp from "../assets/images/svg/crypto-icons/gbp.svg"
import xrp from "../assets/images/svg/crypto-icons/xrp.svg"
import ada from "../assets/images/svg/crypto-icons/ada.svg"
import doge from "../assets/images/svg/crypto-icons/doge.svg"
import cdn from "../assets/images/svg/crypto-icons/cdn.svg"
import tusd from "../assets/images/svg/crypto-icons/tusd.svg"

const LastTransactions = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  // Permissions
  const canViewCustomer = useUserCan('view_customer')

  const cryptoIconsMapper = {
    BTC: btc,
    ETH: eth,
    LTC: ltc,
    DASH: dash,
    USDT: usdt,
    "USDT (ERC20)": usdt,
    CAD: cdn,
    USD: usd,
    EUR: eur,
    GBP: gbp,
    XRP: xrp,
    ADA: ada,
    DOGE: doge,
    TUSD: tusd
  }

  useEffect(() => {
    getLastTransactions().then(r => {
      setData(r)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <Card>
        <CardHeader>Last 5 Transactions</CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table className="table-striped table-nowrap align-middle mb-0">
              <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Type</th>
                <th scope="col">Created date</th>
                <th scope="col">Base Asset</th>
                <th scope="col">Quote Asset</th>
                <th scope="col">Customer</th>
                <th scope="col">Amount</th>
                <th scope="col">Usd Amount</th>
                <th scope="col">Fee Pct</th>
                <th scope="col">Status</th>
              </tr>
              </thead>
              <tbody>
              {loading ?
                <tr>
                  <td colSpan={11} className='text-center'>Loading...</td>
                </tr> :
                (data.length > 0 ? data.map((item, index) => {
                  return (
                    <tr key={index} className="align-middle">
                      <td className="fw-medium">#{item.id}</td>
                      <td>
                        <TransactionBadgeIcon
                          transactionId={item.id}
                          transactionType={item.type}
                          invoiceId={item.invoice_id}
                          invoiceAmount={item.usd_amount}
                        />
                      </td>
                      <td>{prettyDate(item.created_at)}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-xxs me-2">
                            <img
                              src={cryptoIconsMapper[item.base_asset.currency.code]}
                              className="img-fluid"
                              alt="3"
                            />
                          </div>
                          <span>{item.base_asset.currency.code}</span>
                        </div>
                      </td>
                      <td>
                        {item.quote_asset?.name ?
                          <div className="d-flex align-items-center">
                            <div className="avatar-xxs me-2">
                              <img
                                src={cryptoIconsMapper[item.quote_asset.currency.code]}
                                className="img-fluid"
                                alt="3"
                              />
                            </div>
                            <span>{item.quote_asset.currency.code}</span>
                          </div>
                          : '-'
                        }
                      </td>
                      <td>
                        {canViewCustomer ?
                          <span>
                            <Link to={`/customers/show/${item.wallet.user.id}`}>
                              #{item.wallet.user.id} {item.wallet.user.name}
                            </Link>
                            <Link to="#" onClick={() => openLinkInNewTab(`/customers/show/${item.wallet.user.id}`)}>
                              <i className="ri-share-box-line text-muted fs-14 ms-1"></i>
                            </Link>
                          </span> :
                          <span>#{item.wallet.user.id} {item.wallet.user.name}</span>
                        }
                      </td>
                      <td>{isTwoPrecision(item.base_asset.currency.code, item.base_amount)}</td>
                      <td>{toTwoPrecision(item.usd_amount)}</td>
                      <td>{Number(item.fee_pct)}%</td>
                      <td>
                        <span className={'badge badge-soft-' + item.status_color}>
                          {item.status === 'pending' ? (
                            <i className="ri-time-line align-bottom me-1"></i>
                          ) : ( item.status === 'executed' ? (
                            <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                          ) : ( item.status === 'declined' ?
                              <i className="ri-close-circle-line align-bottom me-1"></i> : null
                          ))}
                          {item.status.capitalize()}
                        </span>
                      </td>
                    </tr>
                  )
                }) :
                  <tr>
                    <td colSpan={13} className='text-center'>No data</td>
                  </tr>
                )
              }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default LastTransactions
