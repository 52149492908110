import {toast} from "react-toastify"
import moment from "moment-timezone"
import React from "react"

export const twoPrecisionAssets = [
	'USD',
	'EUR',
	'USDT',
	'USDT (ERC20)',
	'GBP',
  'CAD',
  'TUSD'
];

export const timeZone = import.meta.env.VITE_APP_TIMEZONE ? import.meta.env.VITE_APP_TIMEZONE : 'UTC'

export function isTwoPrecision(assetCode, val) {
  let amount = parseFloat(val)

  if (twoPrecisionAssets.includes(String(assetCode).toUpperCase())) {
    amount = amount.toFixed(2)
  } else {
    amount = amount.toFixed(8).replace(/0+$/, "")
  }

  if (Number.isInteger(parseFloat(val))) {
    return Number(amount).toFixed(2)
  }

  if (!val) return 0

  return amount
}

export function toTwoPrecision(val) {
  return (Number(val) || 0).toFixed(2)
}

export function copyToClipboard(data, message) {
    if (typeof data !== 'string') data = ''
    navigator.clipboard.writeText(data)
    toast.success(message || 'Copied to clipboard!')
}

export function toDateFormat(date) {
	return moment(date).format('YYYY-MM-DD')
}

export function prettyDate(date, withTime = true, withoutSeconds = false) {
  date = moment.tz(date, 'UTC').format()

  return (
		<span className="text-nowrap">
			<span>{moment.tz(date, timeZone).format(`DD MMM, yyyy`)}</span>
			{withTime && (
				<span className="ms-1 text-muted fs-12">{moment.tz(date, timeZone).format(withoutSeconds ? 'HH:mm' : 'HH:mm:ss')}</span>
			)}
		</span>
	)
}

export function openLinkInNewTab(link) {
  const a = document.createElement('a')
  a.href = link
  a.target = '_blank'
  const e = new MouseEvent('click', {
    ctrlKey: true,
    metaKey: true,
  })
  a.dispatchEvent(e)
}

export function prepareQueryString(params) {
  let paramKeys = []

  Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      params[key].forEach((val) => {
        paramKeys.push(key + '[]=' + encodeURIComponent(val))
      })
    } else {
      paramKeys.push(key + '=' + encodeURIComponent(params[key]))
    }

    return paramKeys
  })

  return paramKeys && paramKeys.length ? paramKeys.join('&') : ""
}

export function formatStringDate(eventDate) {
  let date = eventDate.toDateString().split(' ')
  let time = eventDate.toTimeString().split(' ', 1)
  let timeString = moment.tz(eventDate, timeZone).format()
  let regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}).*/
  let match = timeString.match(regex)
  let hours

  if (match && match.length > 1) {
    const dateTimeString = match[1];
    const restOfString = timeString.slice(dateTimeString.length);
    hours = restOfString.split(':')
  }

  let systemTimeZone = hours.length > 1 ? 'GMT' + hours[0] + hours[1] : 'GMT'

  return date[0] + ',' + ' ' + date[1] + ' ' + date[2] + ' ' + date[3] + ' ' + time[0] + ' ' + systemTimeZone
}

export function replacer(key, value) {
  if(value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

export function reviver(key, value) {
  if(typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

export function pluck(array, key) {
  return array.map(o => o[key]);
}

export function slugify(text, separator = '_') {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[\s_]+/g, separator)
    .replace(/[^\w№#$-]+/g, '')
    .replace(/_{2,}/g, separator)
}
